/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: prop-types library.
import _ from 'underscore' // Npm: underscore.js library.
import { useLazyQuery } from '@apollo/client' // Npm: Apollo client.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { HiArrowUturnDown } from 'react-icons/hi2' // Npm: react-icons library.
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useBreakpointValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DateRangePicker from 'components/DateRangePicker'
import TableSpinner from 'components/TableSpinner'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'


/*
 * GRAPHS
 */
import MccReadSmsQuery from './__query__/index.mcc.readSms.query'


/*
 * STYLES
 */
import { cellStyle, headerStyle, rowStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Const assignment.
  const _skipDifference = 8

  // Hook assignment.
  const [dateRange, setDateRange] = React.useState({
    'selection': {
      'startDate': Object.DateRangeProvider()?.startDate,
      'endDate': Object.DateRangeProvider()?.endDate,
      'key': 'selection'
    }
  })
  const [smsRead, setSmsRead] = React.useState([])
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [skipPage, setSkipPage] = React.useState(0)
  const [QueryMccReadSms, QueryMccReadSmsResponse] = useLazyQuery(MccReadSmsQuery, {
    'variables': {
      'customerId': passOn?.customerId,
      'startDate': dateRange?.selection.startDate,
      'endDate': dateRange?.selection.endDate
    },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Event handler
  React.useEffect(() => {
    // Async handler.
    const _Async = async () => {
      // Refetch data on date range change.
      const { data, error } = await QueryMccReadSms({ 'variables': { 'customerId': passOn?.customerId, 'startDate': dateRange.selection.startDate, 'endDate': dateRange.selection.endDate } })

      // If getting data caught exception then report failure.
      if (error instanceof Error) throw error

      // Update state.
      setSmsRead(_.compact(Object.entries(data?.MccReadSms?._smsCountGroupedByCommonCost)) ?? {})
    }; _Async().catch(e => e).then(j => j)
  }, [dateRange, passOn])

  // Return component.
  return (
    <>
      <Flex className='reportsCustomer base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justifyContent='space-between' alignItems='center'>
            <Flex flex={0.6} gap='12px' flexDir='column'>
              <Text as='h3' fontSize={17} fontWeight={500} display='flex' alignItems='center' color='brand.500' gap='6px'><HiArrowUturnDown />{_isCurrentViewMobile ? 'Filter Rows' : 'Filter Rows based on Date'}.</Text>
              <DateRangePicker
                startDate={dateRange?.selection?.startDate}
                endDate={dateRange?.selection?.endDate}
                w='100%'
                fontSize='13px'
                height='max-content'
                gap='6px'
                setSelectDate={setDateRange}
              />
            </Flex>
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='md'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr
                  style={headerStyle}
                  position='sticky'
                  top={0}
                  zIndex='docked'>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    S.No.
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Country Name
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Total Sms
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Revenue ( {passOn?.currency} )
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Cost ( {passOn?.currency} )
                  </Td>
                  <Td
                    style={rowStyle}>
                    Margin ( {passOn?.currency} )
                  </Td>
                </Tr>
              </Thead>
              {QueryMccReadSmsResponse.loading ? (
                <TableSpinner
                  isLoading={true}
                  chopHeightFromHundredPercentage={
                    _tableHeaderHeightRef?.current?.clientHeight
                  }
                />
              ) : 0 === smsRead?.length || (0 < smsRead?.length && _.every(_.pluck(smsRead, 'status'), j => 'NO_SMS_FOUND' === j || 'CUSTOMERS_NOT_FOUND' === j)) ? (
                <TableSpinner
                  isLoading={false}
                  isEmpty={true}
                  chopHeightFromHundredPercentage={
                    _tableHeaderHeightRef?.current?.clientHeight
                  }
                />
              ) : (
                <Tbody style={cellStyle}>
                  {smsRead?.map(
                    (item, __index) => (
                      <Tr key={String.random(8)}>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {' '}
                          {`${__index + (skipDifference * skipPage) + 1}.`}
                        </Td>
                        <Td
                          width='100%'
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.[0] ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.[1]?._totalCount ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {(item?.[1]?._customerTotalDeliveredCost + item?.[1]?._customerTotalPendingCost + item?.[1]?._customerTotalRejectedCost)?.toFixed?.(5) ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          { (item?.[1]?._vendorTotalDeliveredCost + item?.[1]?._vendorTotalPendingCost + item?.[1]?._vendorTotalRejectedCost)?.toFixed?.(5) ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}>
                          {(item?.[1]?._customerTotalDeliveredCost + item?.[1]?._customerTotalPendingCost + item?.[1]?._customerTotalRejectedCost - item?.[1]?._vendorTotalDeliveredCost + item?.[1]?._vendorTotalPendingCost + item?.[1]?._vendorTotalRejectedCost)?.toFixed?.(5) ?? 0}
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(QueryMccReadSmsResponse?.data?.MccReadSms, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)

