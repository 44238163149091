/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { Flex } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import MccMncSelector from 'components/MccMncSelector'
import KeywordDirectorySelector from 'components/KeywordDirectorySelector'
import SenderIdsDirectorySelector from 'components/SenderIdsDirectorySelector'
import CustomerAccountSelector from 'components/CustomerAccountSelector'
import VendorAccountSelector from 'components/VendorAccountSelector'
import { MemoizedInput, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import ContentTranslationCreateMutation from './__mutation__/index.contentTranslation.create.mutation'
import ContentTranslationUpdateMutation from './__mutation__/index.contentTranslation.update.mutation'
import ContentTranslationReadQuery from './__query__/index.contentTranslation.read.query'


/*
 * OBJECTS
 */
const Index = ({ isOpen, type, isCreateOnly, isVendor, onClose, passOn }) => {
  // Const assignment.
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [error, setError] = React.useState('')
  const [regex, setRegex] = React.useState(void 0)
  const [mcc, setMcc] = React.useState(void 0)
  const [mnc, setMnc] = React.useState([])
  const [omitWords, setOmitWords] = React.useState([])
  const [prefix, setPrefix] = React.useState(void 0)
  const [suffix, setSuffix] = React.useState(void 0)
  const [accountType, setCustomerType] = React.useState(type)
  const [globalTranslationKeyword, setGlobalTranslationKeyword] = React.useState(void 0)
  const [selectedAccountId, setSelectedAccountId] = React.useState([])
  const [keywordDirectoryId, setKeywordDirectoryId] = React.useState(void 0)
  const [keywordIds, setKeywordIds] = React.useState([])
  const [senderIdDirectoryId, setSenderIdDirectoryId] = React.useState(void 0)
  const [senderIds, setSenderIds] = React.useState([])
  const [strategy, setStrategy] = React.useState(void 0)
  const [MutationContentTranslationCreate, MutationContentTranslationCreateResponse] = useMutation(ContentTranslationCreateMutation)
  const [MutationContentTranslationUpdate, MutationContentTranslationUpdateResponse] = useMutation(ContentTranslationUpdateMutation)
  const _QueryContentTranslationRead = useQuery(ContentTranslationReadQuery, { 'variables': { 'contentTranslationId': passOn?.contentTranslationId }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Object assignment.
  const _SubmitForm = async e => {
    // Local variable.
    let _accountIds, _keywordDirectory, _keywordIds, _mcc, _mnc, _senderIdDirectoryId, _senderIds

    // Prevent default behavior.
    e?.preventDefault()

    // Reset error.
    setError('')

    // Variable assignment.
    _accountIds = selectedAccountId
    _mcc = mcc
    _mnc = mnc
    _keywordDirectory = keywordDirectoryId
    _keywordIds = keywordIds
    _senderIds = senderIds
    _senderIdDirectoryId = senderIdDirectoryId

    // Only update mnc if it is available.
    if (!_.isEmpty(selectedAccountId)) _accountIds = selectedAccountId?.map(i => i?.includes('(') && i?.includes(')') ? i.split('(')[1].split(')')[0] : i)
    if (mcc && (mcc?.includes('(') || mcc?.includes(')'))) _mcc = mcc.split('(')[1].split(')')[0]
    if (mnc && 0 < mnc?.length) _mnc = _.compact(_.flatten(mnc.map(r => r?.includes('(') && r?.includes(')') ? r.split('(')[1].split(')')[0] : r)))
    if (keywordDirectoryId && (keywordDirectoryId?.includes('(') || keywordDirectoryId?.includes(')'))) _keywordDirectory = keywordDirectoryId.split('(')[1].split(')')[0]
    if (keywordIds && 0 < keywordIds?.length) _keywordIds = _.compact(_.flatten(keywordIds.map(r => r?.includes('(') && r?.includes(')') ? r.split('(')[1].split(')')[0] : r)))
    if (senderIdDirectoryId && (senderIdDirectoryId?.includes('(') || senderIdDirectoryId?.includes(')'))) _senderIdDirectoryId = senderIdDirectoryId.split('(')[1].split(')')[0]
    if (senderIds && 0 < senderIds?.length) _senderIds = _.compact(_.flatten(senderIds.map(r => r?.includes('(') && r?.includes(')') ? r.split('(')[1].split(')')[0] : r)))

    // Variable assignment.
    const _data = {
      prefix,
      suffix,
      regex,
      strategy,
      omitWords,
      globalTranslationKeyword,
      type,
      [isVendor ? 'vendorAccountIds' : 'customerAccountIds']: _accountIds,
      'mcc': _mcc,
      'mnc': _mnc,
      'keywordId': _keywordIds,
      'senderId': _senderIds,
      'keywordDirectoryId': _keywordDirectory,
      'senderIdDirectoryId': _senderIdDirectoryId
    }

    // Schema assignment for joi validation.
    const _JoiSchema = JoiBrowser.object({
      [isVendor ? 'vendorAccountIds' : 'customerAccountIds']: JoiBrowser.array().items(JoiBrowser.string().required()).required(),
      'prefix': JoiBrowser.string().allow('').allow(null),
      'suffix': JoiBrowser.string().allow('').allow(null),
      'strategy': JoiBrowser.string().allow('').allow(null),
      'regex': JoiBrowser.string().allow('').allow(null),
      'mcc': JoiBrowser.string().allow('').allow(null),
      'mnc': JoiBrowser.array(),
      'globalTranslationKeyword': JoiBrowser.string().allow('').allow(null),
      'omitWords': JoiBrowser.array().items(JoiBrowser.string().allow('').allow(null))
    }).options({ 'allowUnknown': true })

    // Remove strategy if it is empty.
    if (_.isEmpty(strategy)) delete _data.strategy

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_data)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // If isCreateOnly is true then create new CT else update existing CT.
    if (isCreateOnly) {
      // Create new CT.
      const _MutationContentTranslation = await MutationContentTranslationCreate({ 'variables': _data })

      // If creating or updating CT caught an exception then report failure.
      if (_MutationContentTranslation instanceof Error) return _MutationContentTranslation

      // Report success.
      toast(_MutationContentTranslation?.data?.ContentTranslationCreate?.message)

      // Close modal.
      onClose()
    } else if (!isCreateOnly) {
      // Update existing CT.
      const _MutationContentTranslation = await MutationContentTranslationUpdate({
        'variables': {
          'contentTranslationId': _.isEmpty(passOn?.contentTranslationIds) ? void 0 : passOn?.contentTranslationId,
          'contentTranslationIds': _.isEmpty(passOn?.contentTranslationIds) ? void 0 : passOn?.contentTranslationIds,
          ..._data
        }
      })

      // If creating or updating CT caught an exception then report failure.
      if (_MutationContentTranslation instanceof Error) return _MutationContentTranslation

      /*
       * Loop over all messages and there status
       * and toast them.
       */
      _MutationContentTranslation?.data?.ContentTranslationUpdate?.map?.(i => toast(i?.message))

      // Close modal.
      onClose()
    }

    // Return void 0.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // Update all states if data is available.
    if (!isCreateOnly && !_.isEmpty(_QueryContentTranslationRead?.data?.ContentTranslationReadUnique) && _successFlags?.includes(_QueryContentTranslationRead?.data?.ContentTranslationReadUnique?.status)) {
      // Update all states.
      setCustomerType(type)
      setSelectedAccountId(_QueryContentTranslationRead?.data?.ContentTranslationReadUnique[isVendor ? 'VendorAccount' : 'CustomerAccount']?.displayName ? [`${_QueryContentTranslationRead?.data?.ContentTranslationReadUnique[isVendor ? 'VendorAccount' : 'CustomerAccount']?.displayName} (${_QueryContentTranslationRead?.data?.ContentTranslationReadUnique[isVendor ? 'VendorAccount' : 'CustomerAccount']?.id})`] : void 0)
      setRegex(_QueryContentTranslationRead?.data?.ContentTranslationReadUnique?.regex)
      setStrategy(_QueryContentTranslationRead?.data?.ContentTranslationReadUnique?.strategy)
      setPrefix(_QueryContentTranslationRead?.data?.ContentTranslationReadUnique?.prefix)
      setSuffix(_QueryContentTranslationRead?.data?.ContentTranslationReadUnique?.suffix)
      setOmitWords(_QueryContentTranslationRead?.data?.ContentTranslationReadUnique?.omitWords)
      setMcc(_.isEmpty(_QueryContentTranslationRead?.data?.ContentTranslationReadUnique?.Mcc) ? void 0 : `${_QueryContentTranslationRead?.data?.ContentTranslationReadUnique?.Mcc?.countryName} (${_QueryContentTranslationRead?.data?.ContentTranslationReadUnique?.Mcc?.mcc})`)
      setMnc(_QueryContentTranslationRead?.data?.ContentTranslationReadUnique?.Mnc?.map?.(j => `(${j?.mnc}) ${j?.network}`))
      setKeywordDirectoryId(_.isEmpty(_QueryContentTranslationRead?.data?.ContentTranslationReadUnique?.KeywordDirectory) ? void 0 : `${_QueryContentTranslationRead?.data?.ContentTranslationReadUnique?.KeywordDirectory?.displayName} (${_QueryContentTranslationRead?.data?.ContentTranslationReadUnique?.KeywordDirectory?.id})`)
      setKeywordIds(_QueryContentTranslationRead?.data?.ContentTranslationReadUnique?.Keyword?.map?.(j => `${j?.keyword} (${j?.id})`))
      setSenderIdDirectoryId(_.isEmpty(_QueryContentTranslationRead?.data?.ContentTranslationReadUnique?.SenderIdDirectory) ? void 0 : `${_QueryContentTranslationRead?.data?.ContentTranslationReadUnique?.SenderIdDirectory?.displayName} (${_QueryContentTranslationRead?.data?.ContentTranslationReadUnique?.SenderIdDirectory?.id})`)
      setSenderIds(_QueryContentTranslationRead?.data?.ContentTranslationReadUnique?.SenderId?.map?.(j => `${j?.senderId} (${j?.id})`))
      setGlobalTranslationKeyword(_QueryContentTranslationRead?.data?.ContentTranslationReadUnique?.globalTranslationKeyword)
    }
  }, [isOpen, passOn, _QueryContentTranslationRead])

  // Const assignment.
  const _isLoading = MutationContentTranslationUpdateResponse.loading || MutationContentTranslationCreateResponse.loading
  const _isInputDisabled = _isLoading || _QueryContentTranslationRead?.loading

  // Return component.
  return (
    <form onSubmit={_SubmitForm}>
      <Flex>
        <Flex gap='22px' flexDir='column' w='100%'>
          <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
            <MemoizedSelect
              isRequired={true}
              name='accountType'
              label='Select Account'
              placeholder='e.g. "Account Type"'
              isInvalid={error?.includes('accountType')}
              error={error}
              options={Object.React.App.enums.CONTENT_TRANSLATION_TYPE.enums?.map(i => i.key)}
              data={isCreateOnly ? accountType : type}
              disabled={type || !isCreateOnly}
            />
            {
              isVendor ? (
                <VendorAccountSelector
                  isRequired={true}
                  disabled={_isInputDisabled}
                  name='vendorAccountIds'
                  label='Select Account'
                  placeholder='e.g. "Select Account"'
                  onChange={({ target }) => setSelectedAccountId(_.compact([target?.value]))}
                  isInvalid={error?.includes('vendorAccountIds')}
                  error={error}
                  data={isCreateOnly ? void 0 : _.first(selectedAccountId)}
                  key={isCreateOnly ? void 0 : _.first(selectedAccountId)}
                />
              ) : (
                <CustomerAccountSelector
                  isRequired={true}
                  disabled={_isInputDisabled}
                  name='customerAccountIds'
                  label='Select Account'
                  placeholder='e.g. "Select Account"'
                  onChange={({ target }) => setSelectedAccountId(_.compact([target?.value]))}
                  isInvalid={error?.includes('customerAccountIds')}
                  error={error}
                  data={isCreateOnly ? void 0 : _.first(selectedAccountId)}
                  key={isCreateOnly ? void 0 : _.first(selectedAccountId)}
                />
              )
            }
          </Flex>
          <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
            <MemoizedInput
              isRequired={false}
              disabled={_isInputDisabled}
              name='prefix'
              label='Prefix'
              placeholder='e.g. "Word to add at the start of the message"'
              onChange={({ target }) => setPrefix(target?.value)}
              isInvalid={error?.includes('prefix')}
              error={error}
              data={prefix}
            />
            <MemoizedInput
              disabled={_isInputDisabled}
              name='suffix'
              label='Suffix'
              placeholder='e.g. "Word to add at the end of the message"'
              onChange={({ target }) => setSuffix(target?.value)}
              isInvalid={error?.includes('suffix')}
              error={error}
              data={suffix}
            />
          </Flex>
          <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
            <MemoizedSelect
              disabled={_isInputDisabled}
              name='strategy'
              label='Select Match Type'
              placeholder='e.g. "REPLACE"'
              onChange={({ target }) => setStrategy(target?.value ?? null)}
              isInvalid={error?.includes('strategy')}
              error={error}
              options={Object.React.App.enums.CONTENT_TRANSLATION_STRATEGY.enums?.map(i => i.key)}
              value={strategy}
            />
            <MemoizedInput
              disabled={_isInputDisabled}
              name='regex'
              label='Original Keyword (Regex)'
              placeholder='e.g. "Original Keyword (Regex)"'
              onChange={({ target }) => setRegex(target?.value)}
              isInvalid={error?.includes('regex')}
              error={error}
              data={regex}
            />
          </Flex>
          <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
            <MccMncSelector
              disabled={_isInputDisabled}
              mccValue={mcc}
              mncValue={mnc}
              inValidMnc={error?.includes('mnc')}
              inValidMcc={error?.includes('mcc')}
              onChange={i => {
                // Update form data.
                setMcc(i.mcc)
                setMnc(i.mnc)
              }}
            />
            <KeywordDirectorySelector
              disabled={_isInputDisabled}
              keywordDirectoryValue={keywordDirectoryId}
              keywordValue={keywordIds}
              inValidKeyword={error?.includes('keyword')}
              inValidKeywordDirectory={error?.includes('keywordDirectory')}
              onChange={i => {
                // Update form data.
                setKeywordDirectoryId(i.keywordDirectory)
                setKeywordIds(i.keyword)
              }}
            />
            <SenderIdsDirectorySelector
              disabled={_isInputDisabled}
              senderIdDirectoryValue={senderIdDirectoryId}
              senderIdValue={senderIds}
              inValidSenderId={error?.includes('senderId')}
              inValidSenderIdDirectory={error?.includes('senderIdDirectory')}
              onChange={i => {
                // Update form data.
                setSenderIdDirectoryId(i.senderIdDirectory)
                setSenderIds(i.senderId)
              }}
            />
          </Flex>
          {
            _.isEmpty(keywordIds) ? (
              <MemoizedInput
                isRequired={false}
                disabled={_isInputDisabled}
                name='globalTranslationKeyword'
                label='Global Translation Keyword'
                placeholder='e.g. "SuperCharged message @v1"'
                onChange={({ target }) => setGlobalTranslationKeyword(target?.value)}
                isInvalid={error?.includes('globalTranslationKeyword')}
                error={error}
                data={globalTranslationKeyword}
              />
            ) : void 0
          }
          <MemoizedInput
            isRequired={false}
            disabled={_isInputDisabled}
            name='omitWords'
            label='Omit Words (Separated by comma ,)'
            placeholder='e.g. "DIRTY, SMPP, ROCKING"'
            onChange={({ target }) => setOmitWords(target?.value)}
            isInvalid={error?.includes('omitWords')}
            error={error}
            data={omitWords}
            isMultiple={true}
            separators={[',', 'Enter', 'Tab']}
          />
        </Flex>
      </Flex>
      <SubmitButton
        onSubmit={_SubmitForm}
        defaultText='Upsert Content Translation'
        disabled={_isInputDisabled}
        isLoading={_isLoading} />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isOpen': PropTypes.bool,
  'isCreateOnly': PropTypes.bool,
  'isVendor': PropTypes.bool,
  'type': PropTypes.string,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}
Index.defaultProps = {}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
