/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import Moment from 'moment' // Npm: moment.js library.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import _ from 'underscore' // Npm: underscore library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import TableSpinner from 'components/TableSpinner'
import DownloadToExcel from 'components/DownloadToExcel'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import Modal from 'components/Modal'
import CustomerAccountUpsert from 'components/CustomerAccountUpsert'
import CustomerAccountDelete from 'components/CustomerAccountDelete'
import CustomerAccountDropDownOptions from 'components/CustomerAccountDropDownOptions'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * GRAPHS
 */
import CustomerAccountReadQuery from './__query__/index.customerAccount.read.query'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [searchQuery, setSearchQuery] = React.useState('')
  const [skipPage, setSkipPage] = React.useState(0)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const { 'onOpen': onCustomerAccountCreateOpen, 'isOpen': isCustomerAccountCreateOpen, 'onClose': onCustomerAccountCreateClose } = useDisclosure()
  const { 'onOpen': onCustomerAccountUpdateOpen, 'isOpen': isCustomerAccountUpdateOpen, 'onClose': onCustomerAccountUpdateClose } = useDisclosure()
  const { 'onOpen': onCustomerAccountDeleteOpen, 'isOpen': isCustomerAccountDeleteOpen, 'onClose': onCustomerAccountDeleteClose } = useDisclosure()
  const _QueryCustomerAccountRead = useQuery(CustomerAccountReadQuery, { 'variables': { 'customerId': passOn?.customerId, 'skip': skipPage * skipDifference, 'take': skipDifference, 'search': searchQuery }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isFirstLoadCompleted = React.useRef(false)
  const _onSearchInputChange = React.useCallback(Debounce(e => setSearchQuery(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Data assignment.
  if (!_QueryCustomerAccountRead?.loading && 0 < _QueryCustomerAccountRead?.data?.CustomerAccountRead?.length) _isFirstLoadCompleted.current = true
  if (0 === _QueryCustomerAccountRead?.data?.CustomerAccountRead?.length || !_.every(_.pluck(_QueryCustomerAccountRead?.data?.CustomerAccountRead, 'status'), i => _successFlags.includes(i))) _isFirstLoadCompleted.current = false

  // Return component.
  return (
    <>
      <Flex className='customerAccount base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justify='space-between' gap={2} flexWrap={{ 'base': 'nowrap', 'md': 'nowrap' }}>
            <Tooltip label='Create new smpp.' fontSize='sm'>
              <Button
                onClick={onCustomerAccountCreateOpen}
                leftIcon={<IoMdAdd />}
                style={buttonStyle}>
                Create  Account
              </Button>
            </Tooltip>
            <DownloadToExcel
              cellsData={_QueryCustomerAccountRead?.data?.CustomerAccountRead?.map((item, __index) => _successFlags.includes(item.status) && item?.Smpp ? ({
                'S.No.': `${(__index + (skipDifference * (skipPage))) + 1}.`,
                'Name': item?.Smpp?.displayName ?? '-',
                'System Id': item?.Smpp?.username ?? '-',
                'Password': item?.Smpp?.password ?? '-',
                'Ip': item?.Smpp?.ip ?? '-',
                'Smpp tps': item?.Smpp?.tps ?? '-',
                'Session Allowed': item?.Smpp?.sessionAllowed ?? '-',
                'Session ttl ( in Seconds )': (0 < item?.Smpp?.sessionTimeoutInterval ? item?.Smpp?.sessionTimeoutInterval / 1000 : item?.Smpp?.sessionTimeoutInterval) ?? '-',
                'Active': item?.Smpp?.isActive ? 'Active' : 'Inactive',
                'CreatedAt': Moment(item?.Smpp?.createdAt).format('DD-MM-YYYY'),
                'UpdatedAt': Moment(item?.Smpp?.updatedAt).format('DD-MM-YYYY'),
              }) : ({}))}
              headersData={[
                'S.No.',
                'Name',
                'System Id',
                'Password',
                'Ip',
                'Smpp tps',
                'Session Allowed',
                'Session ttl ( in Seconds )',
                'Active',
                'CreatedAt',
                'UpdatedAt',
              ].map(i => ({ 'key': i, 'label': i }))} />
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead
                ref={_tableHeaderHeightRef}
                color='rgba(141, 151, 182, 1)'
                fontStyle='DM Sans'
                fontWeight='500'
                fontSize='18px'
                lineHeight='24px'>
                <Tr style={headerStyle}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>S.No.</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Name
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    System Id
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Password
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>IP</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Smpp tps
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Session Allowed
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Session ttl ( in Seconds )
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Active
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>CreatedAt</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>UpdatedAt</Td>
                  <Td
                    style={rowStyle}>
                    Actions</Td>
                </Tr>
                <Tr>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={searchQuery}
                      onChange={_onSearchInputChange}
                      autoFocus
                    />
                  </Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} />
                </Tr>
              </Thead>
              {_QueryCustomerAccountRead?.loading && !_isFirstLoadCompleted.current ? (
                <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : !_isFirstLoadCompleted.current && (0 === _QueryCustomerAccountRead?.data?.CustomerAccountRead?.length || !_.every(_.pluck(_QueryCustomerAccountRead?.data?.CustomerAccountRead, 'status'), i => _successFlags.includes(i?.status))) ? (
                <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : (
                <Tbody style={cellStyle}>
                  {_QueryCustomerAccountRead?.data?.CustomerAccountRead?.map((__item, __index) => __item && __item?.Smpp && (
                    <Tr key={__item?.id}>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {' '}
                        {`${(__index + (skipDifference * (skipPage))) + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {__item?.Smpp?.displayName ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {__item?.Smpp?.username ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {__item?.Smpp?.password ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {__item?.Smpp?.ip ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {__item?.Smpp?.tps ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {__item?.Smpp?.sessionAllowed ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {(0 < __item?.Smpp?.sessionTimeoutInterval ? __item?.Smpp?.sessionTimeoutInterval / 1000 : __item?.Smpp?.sessionTimeoutInterval) ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {true === __item?.Smpp?.isActive ? (
                          <Text
                            margin='auto'
                            width='max-content'
                            borderRadius='10px'
                            padding='5px 15px 5px 15px'
                            color='rgba(117, 81, 255, 1)'
                            bg='rgba(117, 81, 255, .1)'>
                            Active
                          </Text>
                        ) : (
                          <Text
                            margin='auto'
                            width='max-content'
                            borderRadius='10px'
                            padding='5px 15px 5px 15px'
                            color='rgba(238, 93, 80, 1)'
                            bg='rgba(255, 242, 242, 1)'>
                            Inactive
                          </Text>
                        )}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(__item?.Smpp?.createdAt).format('DD-MM-YYYY')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(__item?.Smpp?.updatedAt).format('DD-MM-YYYY')}
                      </Td>
                      <Td
                        style={rowStyle}>
                        <Flex align='center' justify='center'>
                          {
                            <CustomerAccountDropDownOptions
                              type={passOn?.type}
                              customerAccountId={__item?.id}
                              onCustomerAccountUpdate={onCustomerAccountUpdateOpen}
                              onCustomerAccountDelete={onCustomerAccountDeleteOpen}
                            />
                          }
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
        <Modal
          size='2xl'
          title={_isCurrentViewMobile ? 'Customer AC' : 'Customer Account Create'}
          isOpen={isCustomerAccountCreateOpen}
          onClose={onCustomerAccountCreateClose}
          isCentered={false}>
          <CustomerAccountUpsert isCreateOnly={true} />
        </Modal>
        <Modal
          size='2xl'
          title={_isCurrentViewMobile ? 'Customer AU' : 'Customer Account Update'}
          isOpen={isCustomerAccountUpdateOpen}
          onClose={onCustomerAccountUpdateClose}
          isCentered={false}>
          <CustomerAccountUpsert />
        </Modal>
        <Modal
          size='sm'
          title={_isCurrentViewMobile ? 'Customer AD' : 'Customer Account Delete'}
          isOpen={isCustomerAccountDeleteOpen}
          onClose={onCustomerAccountDeleteClose}>
          <CustomerAccountDelete />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryCustomerAccountRead?.data?.CustomerAccountRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
