/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useHistory } from 'react-router-dom' // Npm: React router dom.
import { HiArrowPathRoundedSquare, HiCurrencyEuro, HiFire, HiLockClosed, HiPencil, HiServer, HiTrash, HiUsers } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * OBJECTS
 */
const Index = ({
  customerId,
  type,
  currency,
  onCustomerNotify,
  onCustomerUpdate,
  onCustomerDelete,
  onRateAndRoutePlanAttach,
  onAttachAccountManager,
  onCreditManagementUpsert,
  onCustomerPasswordChange,
  PassOnUpdate
}) => {
  // Hook assignment.
  const _historyRef = useHistory()

  // Return component.
  return (
    <Menu placement='left'>
      <MenuButton
        as={Button}
        margin='auto'
        borderRadius='8px'
        color='gray.500'
        bg='transparent'>
        <HiPencil size={18} />
      </MenuButton>
      <MenuList
        className='dropDownMenu'
        maxHeight='400px'
        overflow='auto'>
        <MenuItem
          gap='12px'
          display='flex'
          pl='20px'
          pr='20px'
          color='gray.500'
          onClick={() => {
            // Push customer id to url.
            PassOnUpdate({ customerId, type })

            // Execute handler.
            onCustomerUpdate()
          }}
          minH='40px'>
          <HiPencil size={18} />
          <span>Edit Customer</span>
        </MenuItem>
        <MenuItem
          gap='12px'
          display='flex'
          pl='20px'
          pr='20px'
          color='gray.500'
          onClick={() => {
            // Push customer id to url.
            PassOnUpdate({ customerId, type })

            // Execute handler.
            onCustomerNotify()
          }}
          minH='40px'>
          <HiFire size={18} />
          <span>Notify</span>
        </MenuItem>
        <MenuItem
          gap='12px'
          display='flex'
          pl='20px'
          pr='20px'
          color='gray.500'
          onClick={() => {
            // Push customer id to url.
            PassOnUpdate({ customerId, type })

            // Execute handler.
            onCustomerDelete()
          }}
          minH='40px'>
          <HiTrash />
          <span>Delete Customer</span>
        </MenuItem>
        <MenuItem
          isDisabled={['RESELLER', 'RETAIL'].includes(type)}
          onClick={() => {
            // Push customer id to url.
            PassOnUpdate({ customerId, type })

            // Execute handler.
            _historyRef.push('/customer/smpp')
          }}
          gap='12px'
          display='flex'
          pl='20px'
          pr='20px'
          color='gray.500'
          minH='40px'>
          <HiServer size={18} />
          <span>Manage SMPP Accounts</span>
        </MenuItem>
        {/* <MenuItem
          gap='12px'
          display='flex'
          pl='20px'
          pr='20px'
          color='gray.500'
          onClick={() => {
            // Push customer id to url.
            PassOnUpdate({ customerId, type, currency })

            // Execute handler.
            _historyRef.push('/customer/payment')
          }}
          minH='40px'>
          <HiCreditCard />
          <span>Manage Payments</span>
        </MenuItem> */}
        <MenuItem
          gap='12px'
          display='flex'
          pl='20px'
          pr='20px'
          color='gray.500'
          onClick={() => {
            // Push customer id to url.
            PassOnUpdate({ customerId, type })

            // Execute handler.
            onAttachAccountManager()
          }}
          minH='40px'>
          <HiUsers size={18} />
          <span>Assign Account Manager</span>
        </MenuItem>
        <MenuItem
          isDisabled={'WHOLESALE' === type}
          gap='12px'
          display='flex'
          pl='20px'
          pr='20px'
          color='gray.500'
          onClick={() => {
            // Push customer id to url.
            PassOnUpdate({ customerId, type })

            // Execute handler.
            onRateAndRoutePlanAttach()
          }}
          minH='40px'>
          <HiArrowPathRoundedSquare size={18} />
          <span>Manage Rate & Route Plan</span>
        </MenuItem>
        <MenuItem
          gap='12px'
          display='flex'
          pl='20px'
          pr='20px'
          color='gray.500'
          onClick={() => {
            // Push customer id to url.
            PassOnUpdate({ customerId, type })

            // Execute handler.
            onCustomerPasswordChange()
          }}
          minH='40px'>
          <HiLockClosed size={18} />
          <span>Change Password</span>
        </MenuItem>
        <MenuItem
          gap='12px'
          display='flex'
          pl='20px'
          pr='20px'
          color='gray.500'
          onClick={() => {
            // Push customer id to url.
            PassOnUpdate({ customerId, type, currency })

            // Execute handler.
            onCreditManagementUpsert()
          }}
          minH='40px'>
          <HiCurrencyEuro size={18} />
          <span>Add Payment</span>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'currency': PropTypes.string,
  'type': PropTypes.oneOf([...Object.React.App.enums.CUSTOMER_TYPE.enums, ...Object.React.App.enums.VENDOR_TYPE.enums]?.map(i => i.key)),
  'onCustomerUpdate': PropTypes.func,
  'onCustomerDelete': PropTypes.func,
  'onCustomerPasswordChange': PropTypes.func,
  'onRateAndRoutePlanAttach': PropTypes.func,
  'customerId': PropTypes.string,
  'PassOnUpdate': PropTypes.func,
  'onAttachAccountManager': PropTypes.func,
  'onCreditManagementUpsert': PropTypes.func
}


/*
 * REDUX
 */
const _MapDispatchToProps = __dispatch => ({
  'PassOnUpdate': u => __dispatch({ 'type': 'PASS_ON_UPDATE', 'PassOn': u })
})


/*
 * EXPORT
 */
export default connect(void 0, _MapDispatchToProps)(Index)
