/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: prop-types library.
import Debounce from 'lodash/debounce' // Npm: lodash debounce.
import { useQuery } from '@apollo/client' // Npm: Apollo client.


/*
 * PACKAGES
 */
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import CustomerAccountReadQuery from './__query__/index.customerAccount.read.query'


/*
 * OBJECTS
 */
const Index = ({ onChange, customerType, ...props }) => {
  // Const assignment.
  const _skipDifference = 1000

  // Hook assignment.
  const _QueryCustomerAccountRead = useQuery(CustomerAccountReadQuery, { 'variables': { customerType, 'take': _skipDifference, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Return component.
  return (
    <MemoizedSelect
      onChange={onChange}
      value={props.value ?? props.data}
      options={_QueryCustomerAccountRead?.data?.CustomerAccountRead?.map(({ id, displayName }) => `${displayName} (${id})`)}
      {...props}
    />
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'onChange': PropTypes.func.isRequired,
  'customerType': PropTypes.string.isRequired
}


/*
 * EXPORT
 */
export default Index
